import {
  Accessor,
  createEffect,
  createSignal,
  For,
  onMount,
  Setter,
  Show,
} from "solid-js";
import { PhosphorIcon } from "~/widgets/icons";
import { SetStoreFunction } from "solid-js/store";
import { Meta } from "@solidjs/meta";
import { PlutoChatStore } from "../chat/pluto_chat_component";
import { closeIconSM } from "~/assets/assets";
import { DividerV2 } from "~/widgets/divider";

export const suggestions = [
  "Gift for my mom’s birthday",
  "Gift for my dad’s birthday",
  "Gift for wedding anniversary",
  "Gift for new year",
];

export function FullScreenInputModal(props: {
  state: PlutoChatStore;
  setState: SetStoreFunction<PlutoChatStore>;
  handleNewMessage: () => Promise<void>;
  closeModal: () => void;
  initialCount: Accessor<number>;
  setCharacterCount: Setter<number>;
}) {
  let textareaRef: HTMLTextAreaElement | undefined;

  const [characterCount, setCharacterCount] = createSignal(
    props.initialCount() || 0
  );
  const [isSuggestionModalOpen, setIsSuggestionModalOpen] = createSignal(
    props.initialCount() > 0 ? false : true
  );
  const maxCharLimit = 500;

  createEffect(() => {
    textareaRef?.focus();
  });

  onMount(() => {
    setAnimate(true);
  });

  const [animate, setAnimate] = createSignal(false);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        const textarea = e.target as HTMLTextAreaElement;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const value = textarea.value;
        textarea.value = value.slice(0, start) + "\n" + value.slice(end);

        textarea.selectionStart = textarea.selectionEnd = start + 1;

        e.preventDefault();
      } else {
        e.preventDefault();
        if (characterCount() > 0 && characterCount() <= maxCharLimit) {
          props.closeModal();
          props.handleNewMessage();
        }
      }
    }
  };

  return (
    <div
      class="fixed inset-0 z-50 flex h-dvh flex-col bg-black text-white opacity-0 duration-300 "
      classList={{
        "opacity-100": animate(),
      }}
    >
      <Meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, interactive-widget=resizes-content"
      />
      <div class="sticky top-0 z-10 flex items-center justify-start bg-black px-4 pb-6 pt-4">
        <button
          class="flex h-9 w-9 items-center justify-center rounded-full border border-basePrimaryMedium text-white"
          onClick={() => {
            props.setState("userMessage", "");
            props.setCharacterCount(0);
            props.closeModal();
          }}
        >
          <img src={closeIconSM} alt="close" />
        </button>
      </div>

      <div class="overflow-y-auto px-2">
        <textarea
          ref={textareaRef}
          class={`no-scrollbar mx-2 mb-4 block min-h-[73px] w-[95%] resize-none overflow-y-auto border-none bg-transparent text-h4 font-normal text-white outline-transparent placeholder:text-h4 placeholder:font-normal placeholder:text-textNormal`}
          placeholder="Tell me who is this gift for & the occasion..."
          value={props.state.userMessage}
          style="min-height: 73px;"
          onInput={(e) => {
            const textarea = e.target as HTMLTextAreaElement;
            textarea.style.height = "73px";
            textarea.style.height = `${textarea.scrollHeight}px`;
            setCharacterCount(textarea.value.length);
            setIsSuggestionModalOpen(textarea.value.length === 0);
          }}
          onChange={(e) => {
            const message = e.currentTarget.value;
            if (message.length <= maxCharLimit) {
              props.setState("userMessage", message);
              setCharacterCount(message.length);
            }
          }}
          onKeyDown={handleKeyDown}
        ></textarea>
        <Show when={isSuggestionModalOpen()}>
          <div class="mx-2 flex flex-col gap-1.5 border-t border-basePrimaryMedium pt-5">
            <For each={suggestions}>
              {(suggestion) => {
                return (
                  <div
                    class="flex cursor-pointer items-center justify-between py-2.5"
                    onClick={() => {
                      props.setState("userMessage", suggestion);
                      setCharacterCount(suggestion.length);
                      setIsSuggestionModalOpen(false);
                    }}
                  >
                    <p class="text-normal text-textNormal hover:text-white">
                      {suggestion}
                    </p>
                    <PhosphorIcon
                      name="arrow-up-left"
                      fontSize={20}
                      size="bold"
                      class="text-baseSecondaryLight hover:text-white"
                    />
                  </div>
                );
              }}
            </For>
          </div>
        </Show>
      </div>
      <Show when={characterCount() > 0}>
        <div class="w-full px-4">
          <DividerV2 class="w-full bg-basePrimaryMedium " />
        </div>
        <div class="z-10 w-full  border-basePrimaryMedium  px-4 pb-4 pt-5">
          <div class="flex items-center justify-between">
            <div
              class={`text-medium ${
                characterCount() > maxCharLimit
                  ? "text-errorDark"
                  : "text-textNormal"
              }`}
            >
              {characterCount()}/{maxCharLimit}
            </div>
            <button
              onClick={async () => {
                props.closeModal();
                props.handleNewMessage();
              }}
              class={`flex h-9 w-9 items-center justify-center rounded-lg text-darkBg disabled:pointer-events-none disabled:opacity-50 ${
                characterCount() > 0 && characterCount() <= maxCharLimit
                  ? "bg-white"
                  : "bg-baseSecondaryDark"
              }`}
              disabled={
                characterCount() > maxCharLimit || characterCount() === 0
              }
            >
              <PhosphorIcon name="arrow-right" fontSize={20} size="bold" />
            </button>
          </div>
        </div>
      </Show>
    </div>
  );
}
