import {
  marqueeGradientLeft,
  marqueeGradientRight,
  plutoLogo,
  plutoMsgPill,
} from "~/assets/assets";
import { Accessor, Component, For, Setter } from "solid-js";
import { SetStoreFunction } from "solid-js/store";
import { Credits } from "~/server/types/pluto";
import { isMobileDevice } from "~/utils/isMobileDevice";
import { PlutoChatStore } from "../chat/pluto_chat_component";

export const IntroCard = ({
  setState,
  setIsFullScreenModalOpen,
  setCharacterCount,
  credits,
}: {
  setState: SetStoreFunction<PlutoChatStore>;
  setIsFullScreenModalOpen: Setter<boolean>;
  setCharacterCount: Setter<number>;
  credits: Accessor<Credits | null>;
}) => {
  const giftRows: { text: string }[][] = [
    [
      { text: "👩 Gift for my mom's birthday" },
      { text: "💍 Wedding gift for my friend" },
      { text: "👨 Wedding gift for my friend" },
      { text: "🎁 Birthday surprise" },
      { text: "🏆 Achievement celebration gift" },
    ],
    [
      { text: "🎅 Merry Christmas gift" },
      { text: "🗓️ Happy new year present" },
      { text: "🏠 House Warming gift" },
      { text: "🎉 Congratulations gift" },
      { text: "🚢 Bon Voyage present" },
    ],
    [
      { text: "🗓️ New year present" },
      { text: "💍 Happy Wedding Anniversary gift" },
      { text: "💍 Valentine's Day gift" },
      { text: "🎂 Retirement celebration" },
      { text: "🌟 Graduation gift" },
    ],
  ];

  const handlePillClick = (text: string) => {
    if (credits()?.available ?? 0 > 0) {
      setState("userMessage", text);
      setState("isTextareaFocused", true);
      setState("isFirstMessage", true);
      setCharacterCount(text.length);
      if (isMobileDevice()) {
        setIsFullScreenModalOpen(true);
      } else {
        setIsFullScreenModalOpen(false);
      }
    }
  };

  return (
    <div class="mt-16 flex flex-col items-center justify-center gap-7 lg:mt-24 lg:flex-none">
      <div>
        <img src={plutoLogo} alt="pluto logo" class="w-[100px] m-auto" />
        <img
          src={plutoMsgPill}
          alt="hey, this is pluto"
          class="m-auto w-[150px]"
        />
        <h1 class="mt-4 font-jakartaSans text-[28px] font-semibold text-white">
          Your AI gifting assistant
        </h1>
      </div>

      <div class="relative w-full">
        <div class="hidden lg:block">
          <img
            src={marqueeGradientLeft}
            alt=""
            class="h-full lg:absolute lg:left-0 lg:z-10"
          />
        </div>
        <div class="hidden lg:block">
          <img
            src={marqueeGradientRight}
            alt=""
            class="h-full lg:absolute lg:right-0 lg:z-10"
          />
        </div>
        <div class="no-scrollbar relative flex w-full snap-x snap-mandatory overflow-x-auto scroll-smooth text-white">

          <div class="flex flex-col gap-3">
            <For each={giftRows}>
              {(row) => <MarqueeRow pills={row} onPillClick={handlePillClick} />}
            </For>
          </div>
        </div>
      </div>
    </div>
  );
};

const MarqueeRow: Component<{
  pills: { text: string }[];
  onPillClick: (text: string) => void;
}> = (props) => {
  const duplicatedPills = [...props.pills, ...props.pills];

  return (
    <div class="relative flex overflow-x-hidden text-white">
      <For each={[1, 2]}>
        {(key) => (
          <div
            class={`flex whitespace-nowrap ${key === 2 ? "absolute top-0 animate-marquee2" : "animate-marquee"
              }`}
          >
            <For each={duplicatedPills}>
              {(pill) => (
                <GiftPill
                  text={pill.text}
                  onClick={() => props.onPillClick(pill.text)}
                />
              )}
            </For>
          </div>
        )}
      </For>
    </div>
  );
};

const GiftPill: Component<{ text: string; onClick: () => void }> = (props) => (
  <button
    class="text-4xl mx-1.5 flex h-8 cursor-pointer items-center gap-2 whitespace-nowrap rounded-lg bg-basePrimaryDark px-2.5 py-3 text-medium text-white"
    onClick={props.onClick}
  >
    {props.text}
  </button>
);
