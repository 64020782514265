import { For, Setter, Show } from "solid-js";
import { suggestions } from "./full_screen_input_modal";
import { SetStoreFunction } from "solid-js/store";
import { PlutoChatStore } from "../chat/pluto_chat_component";

export function DesktopSuggestions(props: {
  state: PlutoChatStore;
  setState: SetStoreFunction<PlutoChatStore>;
  setCharacterCount: Setter<number>;
  setIsFullScreenModalOpen: Setter<boolean>;
}) {
  const getTextareaHeight = () => {
    const height = props.state.currentTextareaHeight;
    const parsedHeight = parseInt(height);
    return isNaN(parsedHeight) ? 48 : parsedHeight;
  };

  const handleSuggestionClick = (suggestion: string) => {
    props.setState("userMessage", suggestion);
    props.setCharacterCount(suggestion.length);
    props.setIsFullScreenModalOpen(false);
  };

  return (
    <Show when={props.state.isTextareaFocused}>
      <div
        style={{
          bottom: `calc(${getTextareaHeight()}px + 50px)`,
        }}
        class={`absolute bottom-[98px] z-[11] mx-4 flex w-[768px] flex-col gap-1.5 rounded-xl border border-[#313538] bg-[#111]`}
      >
        <For each={suggestions}>
          {(suggestion) => {
            return (
              <div
                class="flex cursor-pointer items-center justify-between px-4 py-2.5 hover:rounded-xl hover:bg-black"
                onClick={(e) => {
                  e.preventDefault();
                  handleSuggestionClick(suggestion);
                }}
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
              >
                <p class="text-normal text-textNormal">{suggestion}</p>
              </div>
            );
          }}
        </For>
      </div>
    </Show>
  );
}
