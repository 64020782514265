import { A } from "@solidjs/router";
import { Show } from "solid-js";

export const CompletedConversation = (props: { canStartNew: boolean }) => (
  <div class="sticky bottom-0 left-0 right-0 bg-basePrimaryDark px-2.5 py-3 text-center lg:flex lg:justify-center lg:gap-1">
    <p class="text-medium text-textNormal">
      This conversation has been marked as completed.
    </p>
    <Show when={props.canStartNew}>
      <p class="text-medium text-textNormal">
        <A href="/pluto/create/new" class="underline">
          Create new gift
        </A>
      </p>
    </Show>
  </div>
);
