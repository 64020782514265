import { A } from "@solidjs/router";
import { Accessor, Component, Match, Switch } from "solid-js";
import { Credits } from "~/server/types/pluto";

interface CreditsDisplayProps {
  credits: Accessor<Credits | null>;
}

export const CreditsDisplay: Component<CreditsDisplayProps> = (props) => {
  return (
    <Switch>
      <Match when={props.credits()?.available === 0}>
        <div class="bg-basePrimaryDark p-3 text-center">
          <p class="text-mediumBold text-[#E0B827]">
            You've used up all your free gift creation
          </p>
          <p class="text-medium text-textNormal">
            To unlock 1 more, share any of the <br />
            <A href="/pluto/create/profile" class="underline">
              previously created gifts
            </A>
          </p>
        </div>
      </Match>
      <Match when={props.credits()?.available === 1}>
        <div class="m-auto mb-4 flex w-4/5 flex-col items-center justify-center text-center">
          <p class="text-mediumBold text-[#E0B827]">
            This is the last gift you can create
          </p>
          <p class="text-medium text-textNormal">
            To unlock 1 more, share any of the previously created gifts
          </p>
        </div>
      </Match>
      <Match when={true}>
        <div class="mb-4 flex justify-center">
          <p class="text-medium text-textNormal">
            {props.credits()?.available} gift creations left
          </p>
        </div>
      </Match>
    </Switch>
  );
};
