import { createSignal, onMount, Show } from "solid-js";
import {
  novaChatBubbleBtm,
  novaChatBubbleTop,
  plutoLogoSM,
  userCircle,
} from "~/assets/assets";
import { ChatHistory } from "~/server/types/pluto";

export const MessageBubble = (props: { message: ChatHistory }) => {
  let parentDivRef: HTMLDivElement | undefined;
  const [isHeight57, setIsHeight57] = createSignal(false);

  onMount(() => {
    if (parentDivRef?.offsetHeight === 57) {
      setIsHeight57(true);
    }
  });

  return (
    <div
      class={`flex ${props.message.sender === "user" ? "justify-end" : "justify-start"}`}
    >
      <div
        class={`flex gap-2 ${props.message.sender === "user" ? "flex-row-reverse items-end" : "items-start"}`}
      >
        <img
          src={props.message.sender === "user" ? userCircle : plutoLogoSM}
          alt={`Avatar of ${props.message.sender}`}
          class="h-8 w-8 rounded-full"
        />
        <div class="flex flex-col gap-2">
          <div
            ref={parentDivRef}
            class={`relative max-w-[440px] rounded-[20px] p-[18px] text-white ${
              props.message.sender === "user"
                ? "ml-9 rounded-br-[4px] border border-[#252525] bg-black"
                : "mr-9 rounded-tl-[4px] bg-plutoPurple"
            } shadow-md`}
          >
            <Show when={props.message.sender === "system"}>
              <img
                src={novaChatBubbleTop}
                class={`absolute right-0 top-0 rounded-tr-[20px] ${isHeight57() ? "h-full" : ""}`}
              />
              <img
                src={novaChatBubbleBtm}
                class={`absolute bottom-0 left-0 rounded-bl-[20px] ${isHeight57() ? "h-full" : ""}`}
              />
            </Show>
            <p class="text-normal">{props.message.content}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
