import { createEffect, createSignal, For, onCleanup, Show } from "solid-js";
import {
  giftIcon,
  giftLoading,
  novaChatBubbleBtm,
  novaChatBubbleTop,
  plutoLogoSM,
} from "~/assets/assets";
import { GiftCardDetails } from "~/server/types/pluto";

const waitingStateCards = [
  {
    title: "What next?",
    description:
      "Load the gift with an amount of your choice (gift balance), and the receiver can use it to shop on one or more brands as they like.",
  },
  {
    title: "Did you know?",
    description:
      "I'm an experimental project by Hubble—a company with 5M+ happy users, secure payments, and hassle-free customer support.",
  },
];

const waitingTexts = [
  "Curating thoughtful brands",
  "Writing personalised wish message",
  "Crafting beautiful creatives",
  "Get ready for it!",
];

export default function ViewGeneratedGiftChatMessage(props: {
  gift: GiftCardDetails | null;
  recipientName: string;
  onClick: () => void;
}) {
  const [loadingProgress, setLoadingProgress] = createSignal([0, 0, 0, 0]);
  let giftLoadingRef: HTMLDivElement | undefined;

  createEffect(() => {
    const startTime = Date.now();
    const firstThreeLinesTime = 15000;
    const lastLineTime = 40000;

    const animateLines = () => {
      const elapsedTime = Date.now() - startTime;

      const calculateLineProgress = (lineIndex: number) => {
        const lineTime = lineIndex < 3 ? firstThreeLinesTime : lastLineTime;
        const lineStartTime =
          lineIndex < 3
            ? lineIndex * firstThreeLinesTime
            : 3 * firstThreeLinesTime;
        const lineEndTime =
          lineIndex < 3
            ? (lineIndex + 1) * firstThreeLinesTime
            : 3 * firstThreeLinesTime + lastLineTime;

        if (elapsedTime <= lineStartTime) return 0;
        if (elapsedTime >= lineEndTime) return 1;

        return (elapsedTime - lineStartTime) / lineTime;
      };

      const lastLineProgress = props.gift
        ? 1
        : Math.min(1, calculateLineProgress(3));

      const progress = [
        calculateLineProgress(0),
        calculateLineProgress(1),
        calculateLineProgress(2),
        lastLineProgress,
      ];

      for (let i = 1; i < 3; i++) {
        if (progress[i - 1] < 1) {
          progress[i] = 0;
        }
      }

      setLoadingProgress(progress);

      if (!props.gift) {
        requestAnimationFrame(animateLines);
      }
    };

    const animationFrame = requestAnimationFrame(animateLines);

    onCleanup(() => {
      cancelAnimationFrame(animationFrame);
    });
  });

  const [currentTextIndex, setCurrentTextIndex] = createSignal(0);
  const [isVisible, setIsVisible] = createSignal(true);

  createEffect(() => {
    const interval = setInterval(() => {
      setIsVisible(false);

      setTimeout(() => {
        setCurrentTextIndex((prev) =>
          Math.min(waitingTexts.length - 1, prev + 1)
        );
        setIsVisible(true);
        if (currentTextIndex() === waitingTexts.length - 1) {
          clearInterval(interval);
        }
      }, 500);
    }, 10000);

    return () => clearInterval(interval);
  });

  function GiftLoadingLottie() {
    return (
      <lottie-player
        ref={giftLoadingRef}
        src={giftLoading}
        autoplay={true}
        loop={true}
      ></lottie-player>
    );
  }

  return (
    <div class="flex items-start gap-2">
      <img src={plutoLogoSM} alt="nova" class="h-8 w-8 rounded-full" />
      <div class="flex flex-col gap-2">
        <Show when={!props.gift}>
          <div
            class={`shadow-md relative max-w-[440px]  rounded-[20px] 
                  rounded-tl-[4px] bg-plutoPurple
                  p-[18px] text-white`}
          >
            <img
              src={novaChatBubbleTop}
              class="absolute right-0 top-0 rounded-tr-[20px]"
            />
            <img
              src={novaChatBubbleBtm}
              class="absolute bottom-0 left-0 rounded-bl-[20px]"
            />
            <p class="text-normal">Creating gift, can take up to 60 sec</p>
          </div>
          <div
            class="shadow-md relative mb-4 
                  h-[328px] w-[279px] rounded-[20px] rounded-tl-[4px] bg-plutoPurple py-3 text-white"
          >
            <img
              src={novaChatBubbleTop}
              class="absolute right-0 top-0 rounded-tr-[20px]"
            />
            <img
              src={novaChatBubbleBtm}
              class="absolute bottom-0 left-0 rounded-bl-[20px]"
            />
            <div class="flex flex-col gap-4">
              <div class="flex flex-col items-center justify-center gap-2 px-3">
                <p class="text-center font-jakartaSans text-smallBold uppercase tracking-[1.1px]">
                  Doing magic
                </p>
                <div class="h-px w-[85px] bg-[linear-gradient(90deg,_#281D2D_0%,_#FFF_50%,_#281D2D_100%)]"></div>
              </div>
              <div class="flex flex-col items-center justify-center  gap-3 px-3">
                <div class="h-20 w-20">
                  <GiftLoadingLottie />
                </div>
                <p
                  class={`
                    text-normal 
                    transition-all
                    duration-500 
                    [text-shadow:0px_1px_2px_rgba(0,_0,_0,_0.25)] 
                    ${isVisible() ? "opacity-100" : "opacity-0"}
                  `}
                >
                  {waitingTexts[currentTextIndex()]}
                </p>
                <div class="flex gap-1">
                  <For each={loadingProgress()}>
                    {(progress) => {
                      return (
                        <div
                          class={`relative h-0.5 w-[60.75px] rounded-[14px] bg-[#59475F]`}
                        >
                          <div
                            class="absolute left-0 top-0 h-full rounded-l-[14px] bg-white"
                            style={{ width: `${progress * 100}%` }}
                          ></div>
                        </div>
                      );
                    }}
                  </For>
                </div>
              </div>
              <div class="no-scrollbar z-0 flex gap-2 overflow-x-scroll px-3">
                <For each={waitingStateCards}>
                  {(card) => (
                    <div class="flex h-fit min-w-[245px] flex-col items-start justify-center gap-2 rounded-xl border border-black bg-[#4A3744] p-3">
                      <p class="font-jakartaSans text-smallBold uppercase tracking-[1.1px]">
                        {card.title}
                      </p>
                      <p class="text-medium [text-shadow:0px_1px_2px_rgba(0,_0,_0,_0.25)]">
                        {card.description}
                      </p>
                    </div>
                  )}
                </For>
              </div>
            </div>
          </div>
        </Show>
        <Show when={props.gift}>
          <div
            class={`shadow-md relative mr-9 max-w-[440px] rounded-[20px] 
      rounded-tl-[4px] bg-plutoPurple
      p-[18px] text-white`}
          >
            <img
              src={novaChatBubbleTop}
              class="absolute right-0 top-0 rounded-tr-[20px]"
            />
            <img
              src={novaChatBubbleBtm}
              class="absolute bottom-0 left-0 rounded-bl-[20px]"
            />
            <div class="flex flex-col gap-3 text-normal">
              <p class="text-normal">
                Here’s the gift for {props.recipientName}, hope they like it and
                you too.
              </p>
              <button
                onClick={props.onClick}
                class="z-30 flex h-[44px] w-[127px] cursor-pointer items-center justify-center gap-1 rounded-[41px] bg-baseTertiaryMedium px-3"
              >
                <img src={giftIcon} alt="gift icon" class="pb-1" />
                <p class="text-buttonMedium font-bold text-textDark">
                  View gift
                </p>
              </button>
            </div>
          </div>
        </Show>
      </div>
    </div>
  );
}
