import { novaLoadingBubble, plutoLogoSM } from "~/assets/assets";
import { ThreeDotLoader } from "~/widgets/button";

export const LoadingIndicator = () => (
  <div class="flex items-start gap-2">
    <img src={plutoLogoSM} alt="nova" class="h-8 w-8 rounded-full" />
    <div class="relative">
      <img src={novaLoadingBubble} alt="" class="w-16" />
      <div class="absolute left-4 top-4">
        <ThreeDotLoader color="#fff" />
      </div>
    </div>
  </div>
);
